<template>
  <div class="form-card analyze-card">
    <input type="checkbox" class="hidden-checkbox" v-model="bootCheck" />
    <p class="m-0 form-card__title text-green">Gefeliciteerd!</p>
    <p class="m-0 form-card__sub-title">
      <!-- Ontvang <span class="text-green">gratis en vrijblijvend</span> 4 Dakwerken
      offertes -->
      {{ address.street }} {{ address.streetNumber }}{{ address.premise }},
      {{ address.settlement }}: komt in aanmerking!
    </p>
    <!-- <p class="m-0 form-card__sub-title">
      met persoonlijk advies van de beste partners/
    </p> -->
    <!-- <p class="m-0 form-card__sub-title">bedrijven uit jouw regio</p> -->
    <!-- <p class="m-0 form-card__description">
      Al meer dan 1,2 miljoen mensen ging je voor.
    </p> -->
    <b-row class="justify-content-center mt-4">
      <div class="d-flex flex-column align-items-start">
        <div class="d-flex justify-content-center mt-2 check-item">
          <!-- <img src="@/assets/imgs/check-success.png" class="mr-2" alt="check" /> -->
          <svg
            class="mr-2"
            width="20"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5 0.32605C5.88442 0.32605 0.5 5.71048 0.5 12.326C0.5 18.9416 5.88442 24.326 12.5 24.326C19.1156 24.326 24.5 18.9416 24.5 12.326C24.5 5.71048 19.1156 0.32605 12.5 0.32605ZM12.5 2.32605C18.0347 2.32605 22.5 6.79136 22.5 12.326C22.5 17.8607 18.0347 22.326 12.5 22.326C6.9653 22.326 2.5 17.8607 2.5 12.326C2.5 6.79136 6.9653 2.32605 12.5 2.32605ZM17.4492 8.32605L11.1992 14.576L7.94922 11.326L6.55078 12.7264L11.1992 17.3768L18.8496 9.72644L17.4492 8.32605Z"
              fill="#0FBC00"
            />
          </svg>

          <p class="m-0 analyze-card__check">
            Er is in 2024 tot
            <span class="text-green">€ 7.000,00 subsidie beschikbaar</span>
            voor dakisolatie
          </p>
        </div>
        <!-- <div class="d-flex justify-content-center mt-1 check-item">
          <img src="@/assets/imgs/check-success.png" class="mr-2" alt="check" />
          <p class="m-0 analyze-card__check">
            Vergelijk offertes en bespaar:
            <span class="text-green">tot 40%</span>
          </p>a
        </div> -->
        <!-- <div
          class="d-flex justify-content-center mt-1 check-item"
          v-if="selected === '5258' && selected === '5261'"
        >
          <img src="@/assets/imgs/check-success.png" class="mr-2" alt="check" />
          <p class="m-0 analyze-card__check">
            Er is tot
            <span class="text-green">€7.000,00 subsidie</span> mogelijk op
            isolatie in 2024
          </p>
        </div> -->
        <!-- <div
          class="d-flex justify-content-center mt-1 check-item"
          v-if="selected !== '5264' && selected !== '5267'"
        >
          <img src="@/assets/imgs/check-success.png" class="mr-2" alt="check" />
          <p class="m-0 analyze-card__check">
            Er is tot
            <span class="text-green">€7.000,00 subsidie</span> mogelijk op
            isolatie in 2024
          </p>
        </div> -->
        <div class="d-flex justify-content-center mt-1 check-item">
          <!-- <img src="@/assets/imgs/check-success.png" class="mr-2" alt="check" /> -->
          <svg
            class="mr-2"
            width="20"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5 0.32605C5.88442 0.32605 0.5 5.71048 0.5 12.326C0.5 18.9416 5.88442 24.326 12.5 24.326C19.1156 24.326 24.5 18.9416 24.5 12.326C24.5 5.71048 19.1156 0.32605 12.5 0.32605ZM12.5 2.32605C18.0347 2.32605 22.5 6.79136 22.5 12.326C22.5 17.8607 18.0347 22.326 12.5 22.326C6.9653 22.326 2.5 17.8607 2.5 12.326C2.5 6.79136 6.9653 2.32605 12.5 2.32605ZM17.4492 8.32605L11.1992 14.576L7.94922 11.326L6.55078 12.7264L11.1992 17.3768L18.8496 9.72644L17.4492 8.32605Z"
              fill="#0FBC00"
            />
          </svg>
          <p class="m-0 analyze-card__check">
            Wij hebben
            <span class="text-green">4 beschikbare offertes</span> voor jou
          </p>
        </div>
      </div>
    </b-row>
    <p class="mt-3 form-card__description">
      Wij hebben
      <span class="font-weight-bold">4 gratis offertes beschikbaar</span> van de
      <br />
      beste specialisten uit jouw regio.
    </p>
    <b-form class="main-form mt-4" @submit="handleSubmit">
      <p class="m-0 form-card__question">Naar wie mogen we deze sturen?</p>
      <b-form-group v-slot="{ ariaDescribedby }" class="mt-3 mb-0">
        <b-form-radio-group
          id="radio-group-1"
          v-model="details.gender"
          :options="genderOptions"
          :aria-describedby="ariaDescribedby"
          name="radio-options"
        >
        </b-form-radio-group>
        <b-form-invalid-feedback>
          Voer een geldige e-mailadres in
        </b-form-invalid-feedback>
      </b-form-group>
      <p v-if="genderNotSelected" class="invalid-text mb-0">
        Selecteer er ten minste één
      </p>

      <b-row>
        <b-col class="mt-3" cols="12" md="6">
          <b-form-input
            type="text"
            v-model="details.firstname"
            @input="handleFieldInput('firstname')"
            placeholder="Voornaam"
            :state="details.firstname ? firstNameValidation : null"
            required
          ></b-form-input>
          <b-form-invalid-feedback v-if="!firstNameValidation">
            <!-- Voer een geldige voornaam in -->
            voornaam niet correct
          </b-form-invalid-feedback> </b-col
        ><b-col class="mt-3" cols="12" md="6">
          <b-form-input
            type="text"
            v-model="details.lastname"
            @input="handleFieldInput('lastname')"
            placeholder="Achternaam"
            :state="details.lastname ? lastNameValidation : null"
            required
          ></b-form-input>
          <b-form-invalid-feedback v-if="!lastNameValidation">
            <!-- Voer een geldige achternaam in -->
            achternaam niet correct
          </b-form-invalid-feedback> </b-col
        ><b-col class="mt-3" cols="12" md="6">
          <b-form-input
            placeholder="Telefoonnummer"
            type="text"
            v-model="details.phone_number"
            @input="handleFieldInput('phone_number')"
            :state="details.phone_number ? phoneValidation : null"
            required
          ></b-form-input>
          <b-form-invalid-feedback v-if="!phoneValidation">
            <!-- Voer een geldige telefoonnummer in -->
            telefoonnummer niet correct
          </b-form-invalid-feedback> </b-col
        ><b-col class="mt-3" cols="12" md="6">
          <b-form-input
            type="text"
            v-model="details.email"
            placeholder="E-mailadres"
            @input="handleFieldInput('email')"
            :state="details.email ? emailValidation : null"
            required
          ></b-form-input>
          <b-form-invalid-feedback v-if="!emailValidation">
            <!-- Voer een geldige e-mailadres in -->
            e-mailadres niet correct
          </b-form-invalid-feedback>
        </b-col>
      </b-row>

      <!-- <p class="form-card__label">
        Ben je geïnteresseerd in het aanschaffen van zonnepanelen <br />
        Kies wat op jou van toepassing is:
      </p>
      <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
        <b-form-radio-group
          v-model="interested"
          :options="interestedOptions"
          :aria-describedby="ariaDescribedby"
          name="radios-stacked"
          stacked
        ></b-form-radio-group>
      </b-form-group> -->
      <!-- <p v-if="isInterestedSelected" class="invalid-text mb-0">
        Selecteer er ten minste één
      </p> -->

      <b-button class="w-100 p-2 mt-3" type="submit" :disabled="leadLoading">
        <b-spinner variant="dark" v-if="leadLoading"></b-spinner>
        <span v-else>AANVRAAG AFRONDEN</span>
      </b-button>
    </b-form>

    <p class="mb-0 mt-4 form-card__text">
      Je gegevens worden uitsluitend gebruikt om jou te voorzien van gratis,
      <br />
      persoonlijk advies en offertes van onze partners.
      <br />
      <span class="font-weight-bold"
        >Dit is volledig vrijblijvend, je zit dus nergens aan vast.</span
      >
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Form",

  props: {
    // selected: {
    //   type: String,
    //   required: true,
    // },
    details: {
      type: Object,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      bootCheck: false,
      nameRegex: /^[a-zA-Z\s.,'-]{1,30}$/,
      streetRegex: /^[a-zA-Z ]*$/,
      phoneRegex: /^(0|\+32|0032|00 32)([-\s]?\d[-\s]?){9}$/,
      emailRegex: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/,
      zipRegex: /^([0-9]{4}[a-zA-Z]{2})$/,
      houseRegex: "^[0-9]+$",
      genderNotSelected: false,

      genderOptions: [
        { text: "Mevrouw", value: "female" },
        { text: "Meneer", value: "male" },
      ],
    };
  },

  methods: {
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      if (this.isValidForm && this.details.gender != "") {
        const phoneNumberWithoutPunctuation = this.details.phone_number.replace(
          /[\s-]/g,
          ""
        );
        let formattedPhoneNumber = phoneNumberWithoutPunctuation;

        if (formattedPhoneNumber.startsWith("0031")) {
          formattedPhoneNumber = `+${formattedPhoneNumber.substring(2)}`;
        } else if (formattedPhoneNumber.startsWith("31")) {
          formattedPhoneNumber = `+${formattedPhoneNumber}`;
        }
        this.details.phone_number = formattedPhoneNumber;
        let { text_answers, ...rest } = this.details;
        this.dakwerkenCreateLead({
          data: {
            ...rest,
            ...this.details,
            bootCheck: this.bootCheck,
            answers: [...this.answers].flat(1),
            ...(text_answers ? { "text_answers[1919]": text_answers } : {}),
            // street: this.allStreets.street,
            // city: this.allStreets.settlement,
            street: this.address.street,
            city: this.address.settlement,
          },
        });
      } else {
        if (this.details.gender == "") {
          this.genderNotSelected = true;
          setTimeout(() => {
            this.genderNotSelected = false;
          }, 2500);
        }
      }
    },
  },

  computed: {
    ...mapGetters(["getErrorResponses"]),
    zipValidation() {
      return this.zipRegex.test(this.details.zip) || this.details.zip === "";
    },
    houseNumberValidation() {
      return (
        this.houseRegex.test(this.details.house_number) ||
        this.details.house_number === ""
      );
    },
    firstNameValidation() {
      if (this.details.firstname === "" && !this.getErrorResponses.firstname) {
        return null;
      }
      if (this.getErrorResponses.firstname == false) {
        return false;
      } else if (
        this.nameRegex.test(this.details.firstname) ||
        this.details.firstname === ""
      ) {
        return true;
      }
      return (
        this.nameRegex.test(this.details.firstname) ||
        this.details.firstname === ""
      );
    },
    lastNameValidation() {
      if (this.details.lastname === "" && !this.getErrorResponses.lastname) {
        return null;
      }
      if (this.getErrorResponses.lastname == false) {
        return false;
      } else if (
        this.nameRegex.test(this.details.lastname) ||
        this.details.lastname === ""
      ) {
        return true;
      }
      return (
        this.nameRegex.test(this.details.lastname) ||
        this.details.lastname === ""
      );
    },
    phoneValidation() {
      if (
        this.details.phone_number === "" &&
        !this.getErrorResponses.phone_number
      ) {
        return null;
      }
      if (this.getErrorResponses.phone_number == false) {
        return false;
      } else if (
        this.phoneRegex.test(this.details.phone_number) ||
        this.details.phone_number === ""
      ) {
        return true;
      }
      return (
        this.phoneRegex.test(this.details.phone_number) ||
        this.details.phone_number === ""
      );
    },
    emailValidation() {
      if (this.details.email === "" && !this.getErrorResponses.email) {
        return null;
      }
      if (this.getErrorResponses.email == false) {
        return false;
      } else if (
        this.emailRegex.test(this.details.email) ||
        this.details.email === ""
      ) {
        return true;
      }
      return (
        this.emailRegex.test(this.details.email) || this.details.email === ""
      );
    },

    isValidForm() {
      return (
        this.firstNameValidation &&
        this.lastNameValidation &&
        this.phoneValidation &&
        this.emailValidation
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.main-form {
  padding: 40px;
  background-color: #f5f5f5;
  border-radius: 20px;
}
.text-green {
  color: #5cba01 !important;
}
.hidden-checkbox {
  opacity: 0;
}
.form-card {
  border-radius: 30px;
  filter: drop-shadow(0 0 13.5px rgba(0, 0, 0, 0.21));
  background-color: #ffffff;
  //   padding: 50px;
  padding: 60px 80px;
  color: #1d1729;

  .invalid-text {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
    font-size: 13px;
  }

  &__title {
    text-align: center;
    font-size: 35px;
    // font-size: 44px;
    color: #1d1729;
    font-weight: bold;
  }
  &__sub-title {
    text-align: center;
    // font-size: 30px;
    font-size: 25px;
    color: #1d1729;
    font-weight: bold;
    line-height: 30px;
  }
  &__question {
    font-size: 28px;
    text-align: start;
    font-weight: bold;
  }
  &__description {
    text-align: center;
    // font-size: 18px;
    font-size: 25px;
    // color: #ffcc01;
    // font-weight: 800;
  }

  &__label {
    // font-size: 22px;
    font-size: 18px;
    letter-spacing: 2px;
    // line-height: 33px;
    color: #000000;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .btn {
    border-radius: 37px;
    background-color: #ffcc01;
    font-size: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1d1729;
    font-weight: bold;
    border: none !important;
    &:hover,
    :active {
      background-color: #ffcc01;
      color: #1d1729;
    }
  }

  &__text {
    //   font-size: 18px;
    font-size: 16px;
    color: #000000;
    text-align: center;
  }

  @media (max-width: 991.99px) {
    padding: 50px 60px;

    &__title {
      font-size: 32px;
    }
    &__sub-title {
      font-size: 22px;
    }
    &__description {
      font-size: 12px;
    }

    &__label {
      font-size: 15px;
    }

    .btn {
      font-size: 18px;
    }
    &__text {
      font-size: 14px;
    }
  }

  @media (max-width: 767.99px) {
    padding: 45px 50px;

    &__title {
      font-size: 28px;
    }
    &__sub-title {
      font-size: 18px;
      line-height: 25px;
    }
    &__description {
      font-size: 11px;
    }

    &__label {
      font-size: 11px;
    }

    .btn {
      font-size: 14px;
      letter-spacing: 1px;
    }
    &__text {
      font-size: 12px;
    }
  }

  @media (max-width: 575.99px) {
    padding: 30px 25px;

    &__title {
      font-size: 24px;
    }
    &__sub-title {
      font-size: 15px;
      line-height: 20px;
    }
    &__description {
      font-size: 10px;
    }

    &__label {
      font-size: 10px;
    }

    .btn {
      font-size: 12px;
      letter-spacing: 1px;
    }
    &__text {
      font-size: 11px;
    }
  }

  @media (max-width: 448.99px) {
    padding: 25px 15px;

    &__title {
      font-size: 20px;
    }
    &__sub-title {
      font-size: 12px;
      line-height: 15px;
    }
    &__description {
      font-size: 9px;
    }

    &__label {
      font-size: 9px;
    }

    .btn {
      font-size: 10px;
      letter-spacing: 1px;
    }
    &__text {
      font-size: 9px;
    }
  }
}
.analyze-card {
  border-radius: 30px;
  filter: drop-shadow(0 0 13.5px rgba(0, 0, 0, 0.21));
  background-color: #ffffff;
  padding: 20px 100px 20px 100px;
  color: #1d1729;

  // &__top-text {
  //   font-size: 35px;
  //   font-weight: 800;
  //   text-align: center;
  // }

  // &__progress-text {
  //   font-size: 18px;
  //   font-weight: 800;
  //   text-align: center;
  //   margin-top: 15px;
  //   margin-bottom: 40px;
  // }

  &__check {
    font-size: 18px;
    font-weight: 700;
  }

  // &__bottom-text {
  //   font-size: 24px;
  //   font-weight: 800;
  // }

  .check-item {
    // img {
    //   width: 18px !important;
    //   height: 18px !important;
    // }
    display: flex;
    align-items: center;
  }

  .bottom-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;

    img {
      width: 35px;
      height: 30px;
    }
  }

  @media (max-width: 991.99px) {
    padding: 40px;
    &__top-text {
      font-size: 30px;
    }

    &__progress-text {
      font-size: 16px;
      margin-bottom: 35px;
    }

    &__check {
      font-size: 16px;
    }

    &__bottom-text {
      font-size: 20px;
    }

    .bottom-item {
      margin-top: 30px;
      img {
        width: 30px;
        height: 25px;
      }
    }
  }

  @media (max-width: 767.99px) {
    padding: 30px;

    &__top-text {
      font-size: 25px;
    }

    &__progress-text {
      font-size: 14px;
      margin-bottom: 30px;
    }

    &__check {
      font-size: 14px;
    }

    &__bottom-text {
      font-size: 18px;
    }

    .bottom-item {
      margin-top: 25px;
      img {
        width: 25px;
        height: 20px;
      }
    }
  }

  @media (max-width: 575.99px) {
    padding: 30px 25px;
    .progress {
      height: 20px;
    }
    &__top-text {
      font-size: 22px;
    }

    &__progress-text {
      font-size: 12px;
      margin-bottom: 25px;
    }

    &__check {
      font-size: 12px;
    }

    &__bottom-text {
      font-size: 16px;
    }

    .bottom-item {
      margin-top: 20px;
      img {
        width: 23px;
        height: 18px;
      }
    }

    .check-item {
      svg {
        width: 12px !important;
        height: 12px !important;
      }
      display: flex;
      align-items: flex-start;
    }
  }

  @media (max-width: 481.99px) {
    padding: 25px 20px;
    &__top-text {
      font-size: 18px;
    }

    &__progress-text {
      font-size: 10px;
      margin-bottom: 25px;
    }

    &__check {
      font-size: 10px;
    }

    &__bottom-text {
      font-size: 14px;
    }

    .bottom-item {
    }
  }
}
</style>
